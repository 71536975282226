.outerMemberToastContainer {
    display: flex;
    padding: 0.3rem;
    border-radius: 4px;
    position: absolute;
    isolation: isolate;
    max-width: 40rem;
    top: 1.8rem;
    left: 1.2rem;
    z-index: 9999;
    box-sizing: border-box;
    transition: transform .6s ease-in-out;
    transform: translateX(calc(-100% - 1.2rem));
}

.memberToastContainer {
    display: flex;
    padding: 0.3rem;
    gap: 0.4rem;
    font-weight: 500;
}

.memberToastContainerOpen {
    transform: translateX(0)
}

.closeIcon {
    width: 1rem !important;
    height: 1rem !important;
    opacity: 0.6;
}

.closeIcon:hover {
    opacity: 1;
}